import Vue from "vue";
var vm = new Vue();

export default {
  showHelpDescriptions: false,
  showPopovers: false,
  showBigIcons: false,
  showDataDebug: process.env.NODE_ENV == "development",
  //
  flowTypes: [
    {
      id: "C",
      icon: "media/opalean/svg/Loading.svg",
      slug: "loading",
      name: vm.$gettext("Loading"),
      description: vm.$gettext("Consignment between a shipper and a carrier"),
      allowedThirdPartyType: ["MC", "MT"], //"shipper", "carrier",
      allowedSubThirdPartyType: [], // "SC", "ST" Removed #562
      allowedSubThirdPartySelect: ["MC", "MT", "MD", "NN" /*"SC", "ST", "SD"*/], // Added #562
    },
    {
      id: "D",
      icon: "media/opalean/svg/Unloading.svg",
      slug: "unloading",
      name: vm.$gettext("Unloading"),
      description: vm.$gettext("Consignment between a carrier and a receiver"),
      allowedThirdPartyType: ["MT", "MD"], //"carrier", "receiver",
      allowedSubThirdPartyType: [], // "ST", "SD" Removed #562
    },
    {
      id: "T",
      icon: "media/opalean/svg/Transfer.svg",
      slug: "transfer",
      name: vm.$gettext("Transfer"),
      description: vm.$gettext("Consignment between un shipper and a receiver"),
      allowedThirdPartyType: ["MC", "MD"], //"shipper", "receiver",
      allowedSubThirdPartyType: [], // "SC", "SD" Removed #562
    },
    // Removed #562
    // {
    //   id: "A",
    //   icon: "media/opalean/svg/Charter.svg",
    //   slug: "charter",
    //   name: vm.$gettext("Charter for"),
    //   description: vm.$gettext("Consignment between a shipper and a carrier for a principal"),
    //   allowedThirdPartyType: [], //"charter",
    //   allowedSubThirdPartyType: ["SC", "SD"],
    // },
  ],
  thirdPartyTypes: [
    {
      id: "MC",
      icon: "media/opalean/svg/Shipper.svg",
      slug: "shipper",
      name: vm.$gettext("Shipper"),
      whoName: vm.$gettext("I am the shipper"),
      description: vm.$gettext("The shipper loads the shipment"),
      allowedThirdPartySelect: ["MC", "MT", "MD", "NN" /*"SC", "ST", "SD"*/], // Added #562
    },
    {
      id: "MT",
      icon: "media/opalean/svg/Carrier.svg",
      slug: "carrier",
      name: vm.$gettext("Carrier"),
      whoName: vm.$gettext("I am the carrier"),
      description: vm.$gettext("The carrier transports the shipment"),
      allowedThirdPartySelect: ["MC", "MT", "MD", "NN" /*"SC"*/, "ST" /*, "SD"*/], // Added #562
    },
    {
      id: "AD",
      icon: "media/opalean/svg/Dock.svg",
      slug: "dock",
      name: vm.$gettext("Dock"),
      whoName: vm.$gettext("I am a dock"),
      description: vm.$gettext("The dock is a step"),
      allowedThirdPartySelect: ["MC", "MT", "MD", "NN" /*"SC"*/, "ST" /*, "SD"*/], // Added #562
      // Needed to EditOperation
    },
    {
      id: "MD",
      icon: "media/opalean/svg/Receiver.svg",
      slug: "receiver",
      name: vm.$gettext("Receiver"),
      whoName: vm.$gettext("I am the receiver"),
      description: vm.$gettext("The recipient receives the shipment"),
      allowedThirdPartySelect: ["MC", "MT", "MD", "NN" /*"SC", "ST", "SD"*/], // Added #562
    },
    {
      id: "NN",
      icon: "media/opalean/svg/Notified.svg",
      slug: "notified",
      name: vm.$gettext("Notified"),
      whoName: vm.$gettext("I am the notified"),
      description: vm.$gettext("The third party is notified of the flow"),
      class: "opalean-info",
      allowedThirdPartySelect: [], // Added #562
    },
    {
      id: "SC",
      icon: "media/opalean/svg/SubShipper.svg",
      slug: "shipper-subcontractor",
      name: vm.$gettext("Shipper Subcontractor"),
      whoName: vm.$gettext("I am the shipper subcontractor"),
      description: vm.$gettext("The shipper subcontractor loads the shipment for a contractor"),
      allowedThirdPartySelect: [], // Added #562
    },
    {
      id: "ST",
      icon: "media/opalean/svg/SubCarrier.svg",
      slug: "carrier-subcontractor",
      name: vm.$gettext("Carrier Subcontractor"),
      whoName: vm.$gettext("I am the carrier subcontractor"),
      description: vm.$gettext("The carrier subcontractor transports the shipment for a contractor"),
      allowedThirdPartySelect: [], // Added #562
    },
    {
      id: "SD",
      icon: "media/opalean/svg/SubReceiver.svg",
      slug: "receiver-subcontractor",
      name: vm.$gettext("Receiver Subcontractor"),
      whoName: vm.$gettext("I am the receiver subcontractor"),
      description: vm.$gettext("The recipient subcontractor receives the shipment for a contractor"),
      allowedThirdPartySelect: [], // Added #562
    },
  ],
  partnerMainRoleTypes: [
    {
      id: "CH",
      icon: "media/opalean/svg/Shipper.svg",
      class: "info",
      slug: "charger",
      name: vm.$gettext("Charger"),
      whoName: vm.$gettext("I am the charger"),
      description: vm.$gettext("The charger loads the goods"),
    },
    {
      id: "TR",
      icon: "media/opalean/svg/Carrier.svg",
      class: "danger",
      slug: "carrier",
      name: vm.$gettext("Carrier"),
      whoName: vm.$gettext("I am the carrier"),
      description: vm.$gettext("The carrier transports the goods"),
    },
    {
      id: "DE",
      icon: "media/opalean/svg/Receiver.svg",
      class: "success",
      slug: "receiver",
      name: vm.$gettext("Receiver"),
      whoName: vm.$gettext("I am the receiver"),
      description: vm.$gettext("The receiver unloads the goods"),
    },
    {
      id: "AD",
      icon: "media/opalean/svg/Charter.svg",
      class: "opalean-tertiary",
      slug: "charter",
      name: vm.$gettext("Alternative receiver"),
      whoName: vm.$gettext("I am the alternative receiver"),
      description: vm.$gettext("The alternative receiver is a warehouse or storage platform"),
    },
  ],
  statusTypes: [
    {
      id: "R",
      name: vm.$gettext("Sent"),
      label: vm.$gettext("Received / Sent"),
      class: "outline-primary",
      icon: "media/opalean/svg/Flow_Opalean_1.svg",
      plain: false,
      actions: ["edit", "search"], //"validate", "create", "edit", "delete", "misc", "adddocument", "search"
    },
    {
      id: "V",
      name: vm.$gettext("Validated"),
      label: vm.$gettext("Received validated"),
      class: "success",
      icon: "media/opalean/svg/Flow_Opalean_1.svg",
      plain: false,
      actions: ["edit"], //"validate", "create", "edit", "delete", "misc", "adddocument"
    },
    {
      id: "M",
      name: vm.$gettext("Waiting"),
      label: vm.$gettext("Waiting to check"),
      class: "info", //info
      icon: "media/opalean/svg/Flow_Opalean_2.svg",
      plain: false,
      actions: ["validate", "reject", "search"], //"validate", "create", "edit", "delete", "misc", "adddocument"
    },
    {
      id: "A",
      name: vm.$gettext("Alert"),
      label: vm.$gettext("Alert"),
      class: "warning",
      icon: "media/svg/icons/Electric/Highvoltage.svg",
      plain: false,
      actions: ["adddocument", "edit"], //"validate", "create", "edit", "delete", "misc", "adddocument"
    },
    {
      id: "L",
      name: vm.$gettext("Claim"),
      label: vm.$gettext("Claim"),
      class: "danger",
      icon: "media/svg/icons/Communication/Spam.svg",
      plain: false,
      actions: ["adddocument", "edit", "search"], //"validate", "create", "edit", "delete", "misc", "adddocument"
    },
    {
      id: "D",
      name: vm.$gettext("Deleted"),
      label: vm.$gettext("Deleted"),
      class: "secondary",
      icon: "media/opalean/svg/Flow_Opalean_4.svg",
      plain: false,
      actions: [],
    },
    {
      id: "B",
      name: vm.$gettext("Draft"),
      label: vm.$gettext("To complete"),
      class: "opalean-dark", //primary
      icon: "media/opalean/svg/Flow_Opalean_3.svg",
      plain: false,
      actions: ["edit", "search"], //"validate", "create", "edit", "delete", "misc", "adddocument"
    },
    {
      id: "N",
      name: vm.$gettext("Notified"),
      label: vm.$gettext("Notified"),
      class: "opalean-info",
      icon: "media/opalean/svg/Notified.svg",
      plain: false,
      actions: [],
    },
  ],
  palletTypes: [
    {
      id: "X",
      name: vm.$gettext("Exchange"),
      icon: "media/svg/icons/Navigation/Arrows-h.svg",
      class: "",
      description: vm.$gettext("An exchanged pallet will be returned"),
    },
    {
      id: "P",
      name: vm.$gettext("One-way"),
      icon: "media/svg/icons/Navigation/Arrow-right.svg",
      class: "",
      description: vm.$gettext("A one-way pallet will be used once"),
    },
    {
      id: "L",
      name: vm.$gettext("Lost"),
      icon: "media/svg/icons/Navigation/Close.svg",
      class: "svg-icon-info",
      description: vm.$gettext("A lost pallet will be trashed at the end of flow"),
    },
  ],
  accountTypes: [
    { name: vm.$gettext("Loaded"), shortName: vm.$gettext("Loa") },
    { name: vm.$gettext("Delivered"), shortName: vm.$gettext("Del") },
    { name: vm.$gettext("Returned"), shortName: vm.$gettext("Ret") },
    { name: vm.$gettext("Transfered"), shortName: vm.$gettext("Trf") },
    { name: vm.$gettext("Impact account"), shortName: vm.$gettext("Ia") },
    { name: vm.$gettext("Impact stock"), shortName: vm.$gettext("Is") },
    { name: vm.$gettext("Unloaded"), shortName: vm.$gettext("Unl") },
  ],
  linkedTools: [
    {
      name: "Shippeo",
      description: vm.$gettext("Lorem ipsum dolor"),
      image: "media/opalean/Shippeo.png",
      url: "https://web.shippeo.com/client/login",
      capabilities: ["CanShippeo"],
    },
    {
      name: "PFM",
      description: vm.$gettext("PAL Bank"),
      image: "media/opalean/PAL.png",
      url: "https://web.shippeo.com/client/login",
      capabilities: ["CanPFM"],
    },
  ],
  filterLabels: [
    // Operations/list
    { name: vm.$gettext("Flow"), key: "OperationType" },
    { name: vm.$gettext("My role"), key: "MyRole" },
    { name: vm.$gettext("Status"), key: "OperationStatus" },
    // Balances/list
    { name: vm.$gettext("Is Partner linked?"), key: "PartnerIsLinked" },
    { name: vm.$gettext("Have awaiting?"), key: "X" },
    { name: vm.$gettext("Have claim?"), key: "X" },
    { name: vm.$gettext("Partner District"), key: "PartnerDistrict" },
    // Partners/list
    { name: vm.$gettext("Main Role"), key: "MainRole" },
    { name: vm.$gettext("Country"), key: "CountryISO" },
    { name: vm.$gettext("City"), key: "City" },
    { name: vm.$gettext("Is linked to me?"), key: "isOpaClient" },
    { name: vm.$gettext("Is active?"), key: "Active" },
  ],
  footerLinks: [
    {
      name: vm.$gettext("About"),
      url: vm.$gettext("https://opalean.fr/"),
    },
    {
      name: vm.$gettext("Contact"),
      url: vm.$gettext("https://opalean.fr/solution-gestion-palettes-europe-contact-renseignements-opalean/"),
    },
  ],
  widgetSizes: [
    // Here change name to id and create name like "Activity", ...
    // Let a widget to be expandable add colSizeExpanded: "md" to object
    { label: vm.$gettext("ActivityWidget"), name: "ActivityWidget", colSize: "sm", colSizeExpanded: "md" },
    { label: vm.$gettext("BalanceWidget"), name: "BalanceWidget", colSize: "sm", colSizeExpanded: "md" },
    { label: vm.$gettext("CreditsWidget"), name: "CreditsWidget", colSize: "sm" },
    { label: vm.$gettext("DebtsWidget"), name: "DebtsWidget", colSize: "sm" },
    { label: vm.$gettext("TotalsWidget"), name: "TotalsWidget", colSize: "sm" },
    { label: vm.$gettext("AlertsWidget"), name: "AlertsWidget", colSize: "md" },
    { label: vm.$gettext("NewsWidget"), name: "NewsWidget", colSize: "lg" },
    { label: vm.$gettext("ContactWidget"), name: "ContactWidget", colSize: "sm" },
    { label: vm.$gettext("LatestOperationsWidget"), name: "LatestOperationsWidget", colSize: "xl" },
  ],
  debtTypes: [
    { id: "OUT", name: vm.$gettext("Debt"), class: "text-opalean-info" },
    { id: "IN", name: vm.$gettext("Credit"), class: "text-info" },
  ],
  statTypes: [
    {
      id: "G",
      name: vm.$gettext("Graphics"),
      class: "info",
      icon: "media/svg/icons/Shopping/Chart-line1.svg",
    },
    {
      id: "L",
      name: vm.$gettext("Lists"),
      class: "opalean-info",
      icon: "media/svg/icons/Text/Bullet-list.svg", //media/svg/icons/Layout/Layout-left-panel-2.svg
    },
    {
      id: "H",
      name: vm.$gettext("Histograms"),
      class: "success",
      icon: "media/svg/icons/Media/Equalizer.svg", //media/svg/icons/Shopping/Chart-bar1.svg
    },
    {
      id: "B",
      name: vm.$gettext("BI"),
      class: "warning",
      icon: "media/svg/icons/Design/Pixels.svg",
    },
    // New 2023
    {
      id: "HYEL",
      name: vm.$gettext("Histograms"),
      class: "yellow",
      icon: "media/svg/icons/Media/Equalizer.svg", //media/svg/icons/Shopping/Chart-bar1.svg
    },
    {
      id: "HLBLU",
      name: vm.$gettext("Histograms"),
      class: "opalean-info",
      icon: "media/svg/icons/Media/Equalizer.svg", //media/svg/icons/Shopping/Chart-bar1.svg
    },
    {
      id: "HDBLU",
      name: vm.$gettext("Histograms"),
      class: "darkblue",
      icon: "media/svg/icons/Media/Equalizer.svg", //media/svg/icons/Shopping/Chart-bar1.svg
    },
    {
      id: "HLGRE",
      name: vm.$gettext("Histograms"),
      class: "success",
      icon: "media/svg/icons/Media/Equalizer.svg", //media/svg/icons/Shopping/Chart-bar1.svg
    },
    {
      id: "HDGRE",
      name: vm.$gettext("Histograms"),
      class: "darkgreen",
      icon: "media/svg/icons/Media/Equalizer.svg", //media/svg/icons/Shopping/Chart-bar1.svg
    },
    {
      id: "HGREY",
      name: vm.$gettext("Histograms"),
      class: "opalean-gray-medium",
      icon: "media/svg/icons/Media/Equalizer.svg", //media/svg/icons/Shopping/Chart-bar1.svg
    },
    {
      id: "HPURP",
      name: vm.$gettext("Histograms"),
      class: "info",
      icon: "media/svg/icons/Media/Equalizer.svg", //media/svg/icons/Shopping/Chart-bar1.svg
    },
    //
    {
      id: "LYEL",
      name: vm.$gettext("Lists"),
      class: "yellow",
      icon: "media/svg/icons/Text/Bullet-list.svg", //media/svg/icons/Layout/Layout-left-panel-2.svg
    },
    {
      id: "LLBLU",
      name: vm.$gettext("Lists"),
      class: "opalean-info",
      icon: "media/svg/icons/Text/Bullet-list.svg", //media/svg/icons/Layout/Layout-left-panel-2.svg
    },
    {
      id: "LDBLU",
      name: vm.$gettext("Lists"),
      class: "darkblue",
      icon: "media/svg/icons/Text/Bullet-list.svg", //media/svg/icons/Layout/Layout-left-panel-2.svg
    },
    {
      id: "LLGRE",
      name: vm.$gettext("Lists"),
      class: "success",
      icon: "media/svg/icons/Text/Bullet-list.svg", //media/svg/icons/Layout/Layout-left-panel-2.svg
    },
    {
      id: "LDGRE",
      name: vm.$gettext("Lists"),
      class: "darkgreen",
      icon: "media/svg/icons/Text/Bullet-list.svg", //media/svg/icons/Layout/Layout-left-panel-2.svg
    },
    {
      id: "LGREY",
      name: vm.$gettext("Lists"),
      class: "opalean-gray-medium",
      icon: "media/svg/icons/Text/Bullet-list.svg", //media/svg/icons/Layout/Layout-left-panel-2.svg
    },
    {
      id: "LPURP",
      name: vm.$gettext("Lists"),
      class: "info",
      icon: "media/svg/icons/Text/Bullet-list.svg", //media/svg/icons/Layout/Layout-left-panel-2.svg
    },
    //
    {
      id: "GYEL",
      name: vm.$gettext("Graphics"),
      class: "yellow",
      icon: "media/svg/icons/Shopping/Chart-line1.svg",
    },
    {
      id: "GLBLU",
      name: vm.$gettext("Graphics"),
      class: "opalean-info",
      icon: "media/svg/icons/Shopping/Chart-line1.svg",
    },
    {
      id: "GDBLU",
      name: vm.$gettext("Graphics"),
      class: "darkblue",
      icon: "media/svg/icons/Shopping/Chart-line1.svg",
    },
    {
      id: "GLGRE",
      name: vm.$gettext("Graphics"),
      class: "success",
      icon: "media/svg/icons/Shopping/Chart-line1.svg",
    },
    {
      id: "GDGRE",
      name: vm.$gettext("Graphics"),
      class: "darkgreen",
      icon: "media/svg/icons/Shopping/Chart-line1.svg",
    },
    {
      id: "GGREY",
      name: vm.$gettext("Graphics"),
      class: "opalean-gray-medium",
      icon: "media/svg/icons/Shopping/Chart-line1.svg",
    },
    {
      id: "GPURP",
      name: vm.$gettext("Graphics"),
      class: "info",
      icon: "media/svg/icons/Shopping/Chart-line1.svg",
    },
  ],
  // Used to translate gettext
  routes: [
    { name: vm.$gettext("route.dashboard") },
    { name: vm.$gettext("route.operations") },
    { name: vm.$gettext("route.operations.list") },
    { name: vm.$gettext("route.operations.create") },
    { name: vm.$gettext("route.operations.edit") },
    { name: vm.$gettext("route.operations.import") },
    { name: vm.$gettext("route.accounts") },
    { name: vm.$gettext("route.stocks") },
    { name: vm.$gettext("route.partners") },
    { name: vm.$gettext("route.partners.list") },
    { name: vm.$gettext("route.partners.create") },
    { name: vm.$gettext("route.partners.edit") },
    { name: vm.$gettext("route.reports") },
    { name: vm.$gettext("route.map") },
    { name: vm.$gettext("login") },
    { name: vm.$gettext("autologin") },
    { name: vm.$gettext("disconnected") },
  ],
  // TimelineTypes TO DELETE ?
  timelineTypes: [
    { id: "O", name: vm.$gettext("Create"), class: "primary" },
    { id: "D", name: vm.$gettext("Added document"), class: "info" },
    { id: "N", name: vm.$gettext("Added notes"), class: "secondary" },
    { id: "A", name: vm.$gettext("Alert"), class: "warning" },
    { id: "C", name: vm.$gettext("Claim"), class: "danger" },
  ],
  preEstablishedNotes: [
    { value: "RS01", text: vm.$gettext("Loader does not return pallets.") },
    { value: "RS02", text: vm.$gettext("The driver does not take back the pallets.") },
    { value: "RS03", text: vm.$gettext("No pallets to take back.") },
  ],
  // Maps
  mapOptions: {
    zoomControl: true,
    zoomControlOptions: {
      position: "",
    },
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: true,
    disableDefaultUi: false,
    styles: [
      {
        featureType: "landscape",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#f3f2f2",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels.icon",
        stylers: [
          {
            color: "#25ae99",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels.text",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "geometry.fill",
        stylers: [
          {
            saturation: -35,
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [
          {
            lightness: 75,
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [
          {
            saturation: -25,
          },
          {
            lightness: 20,
          },
        ],
      },
      {
        featureType: "water",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#25ae99",
          },
          {
            saturation: -50,
          },
          {
            lightness: 70,
          },
        ],
      },
    ],
    // styles: [
    //   {
    //     elementType: "geometry.fill",
    //     stylers: [
    //       {
    //         visibility: "off",
    //       },
    //     ],
    //   },
    //   {
    //     elementType: "geometry.stroke",
    //     stylers: [
    //       {
    //         color: "#ffffff",
    //       },
    //     ],
    //   },
    //   {
    //     elementType: "labels.icon",
    //     stylers: [
    //       {
    //         color: "#25ae99",
    //       },
    //     ],
    //   },
    //   {
    //     featureType: "poi",
    //     elementType: "labels.text",
    //     stylers: [
    //       {
    //         visibility: "off",
    //       },
    //     ],
    //   },
    // ],
  },
};
